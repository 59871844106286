<template>
    <div class="d-flex flex-column flex-root">
        <div class="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat" style="padding:5vh;">
            <div class="modal-header">
                <h2 class="modal-title">
                    {{ $t("foss_infos") }}
                </h2>
            </div>

            <div class="modal-body">
                <div class="kt-iconbox kt-iconbox--info">
                    <div class="kt-iconbox__body" style="text-align: center;">
                        <div class="row">
                            <div class="col-md-3 fossList">
                                <div v-for="([key, value]) in Object.entries(fossData)" :key="key" class="fossListEntry" :class="selected===key ? 'fossSelected':''" @click="selectFoss(key)">
                                    {{ key }}&nbsp;
                                    <span v-if="value.infos !== undefined" class="license-badge" style="float:right;">{{ value.infos.license }}</span>
                                </div>
                            </div>
                            <div class="col-md-9" style="min-height:80vh; max-height:80vh;">
                                <div class style="max-height: 5vh; width:100%; border: 1px solid #ccc; background-color: #eeeeee; text-align: left; padding:10px;">
                                    <h3>
                                        {{ selected }} {{ (fossData[selected] !== undefined && fossData[selected].infos !== undefined) ? ' : ' + fossData[selected].infos.version : '' }}
                                        <span class="badge bg-primary text-white" style="float: right;">
                                            {{ (fossData[selected] !== undefined && fossData[selected].infos !== undefined) ? fossData[selected].infos.license : '' }}
                                        </span>
                                    </h3>
                                </div>
                                <textarea v-model="currentLicenseText" disabled style="min-height:75vh; max-height:75vh; overflow-y: scroll; width:100%; padding:15px;" />
                            </div>
                        </div>

                        <br>

                        <p>{{ $t("foss_thanks") }}</p>
                    </div>
                </div>
            </div>

            <div class="modal-footer" />
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "FossModal",
    data() {
        return {
            selected: "",
            fossData: {},
            options: {
                modules: {
                    toolbar: false,
                }
            },
            currentLicenseText: ""
        };
    },
    mounted() {
        let self = this;
        axios.get('/assets/at_foss.json')
            .then(function(response) {
                let ovngFoss = response.data;
                axios.get('/assets/at_static_foss.json')
                    .then(function(response){
                        self.fossData = { ...ovngFoss, ...response.data }
                        self.selected = Object.keys(self.fossData)[0]
                        self.currentLicenseText = self.fossData[self.selected].text;
                    });
            });
    },
    methods:{
        selectFoss : function(foss){
            console.log("Selected foss : " + foss)
            this.selected = foss;
            this.currentLicenseText = this.fossData[foss].text;
        }
    }
};
</script>

<style scoped>
.fossListEntry {
    width:100%;
    text-align: left;
    padding-left: 5px;
}
.fossList{
    min-height:80vh;
    max-height:80vh;
    border: 1px solid #ccc;
    padding:0px;
    overflow-y:scroll;
    color: #3699ff;
}
.fossListEntry:nth-child(odd) {
    background: #eee;
}
.fossListEntry:nth-child(even) {
    background: #fff;
}
.fossListEntry:hover {
    background-color: #36daff;
    color: white;
    cursor:pointer;
}
.fossSelected {
    background-color: #3699ff !important;
    color: white;
}
.license-badge {
    background-color: #6e7a88 !important;
    color: white;
    font-size: 0.65em;
    padding: 2px;
    border-radius: 3px;
    margin-right:15px;
    align-self: center;
}
</style>